import { Form, Formik } from "formik";

import Button from "../../UI/Button";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import Checkbox from "../../UI/Checkbox";
import ListFiles from "../../UI/ListFiles";
import Card from "../../UI/Card";
import { handleAPIError, TextOneOrMany } from "../../../utils";
import useAuthenticatedAxios from "../../../hooks/use-authenticated-axios";
import FormikSubmitButton from "../../UI/FormikSubmitButton";
import { useImmer } from "use-immer";
import QuittanceSelectTenant from "./QuittanceSelectTenant";
import { useTenantsBalanceQuittance } from "../../../hooks/use-balances";
import TextInput from "../../UI/TextInput";

export default function QuittanceForm({ tenantId, ModalElement }) {
  const axios = useAuthenticatedAxios();
  const [showQuittances, setShowQuittances] = useState(false);
  const [listQuittances, setListQuittances] = useState([]);
  const [isDateFrom, setIsDateFrom] = useState(false);
  const [monthsFromNow, setMonthsFromNow] = useState(0);
  const [tenantQuittanceStatuses, setTenantQuittanceStatuses] = useImmer({});
  const [listPreviewQuittances, setListPreviewQuittances] = useState([]);
  const [hasExistingQuittances, setHasExistingQuittances] = useState(false);
  const [error, setError] = useState("");
  const {
    balanceQuittance,
    monthsFromNow: minMonthsFromNow,
    isLoading: isLoadingQuittances,
  } = useTenantsBalanceQuittance(isDateFrom ? monthsFromNow : 4);

  const getQuittanceTypeForMonth = useCallback(
    (tenantId, monthStr) => {
      const balanceQuittanceTenant = balanceQuittance[tenantId];
      const firstMonthPasAJourIndex = balanceQuittanceTenant?.findIndex(
        (balance) => balance.balance < 0,
      );
      const firstMonthPasAJour = moment(
        balanceQuittanceTenant?.[firstMonthPasAJourIndex]?.month,
        "MM/YYYY",
      );
      const isAJour = firstMonthPasAJourIndex === -1 || !balanceQuittanceTenant;

      // create new quittance status for specified tenant and month
      const month = moment(monthStr, "MM/YYYY");
      const upToDate = isAJour || month.isBefore(firstMonthPasAJour);
      return {
        type: upToDate ? "quittance" : "none",
        up_to_date: upToDate,
      };
    },
    [balanceQuittance],
  );

  useEffect(() => {
    if (tenantId) {
      const monthStr = moment().format("MM/YYYY");
      setTenantQuittanceStatuses({
        [tenantId]: {
          [monthStr]: getQuittanceTypeForMonth(tenantId, monthStr),
        },
      });
    }
  }, [
    balanceQuittance,
    getQuittanceTypeForMonth,
    setTenantQuittanceStatuses,
    tenantId,
  ]);

  function toggleMonth(monthStr) {
    setTenantQuittanceStatuses((prev) => {
      Object.entries(prev).forEach(([tenantId, quittanceStatus]) => {
        if (Object.keys(quittanceStatus).includes(monthStr)) {
          delete quittanceStatus[monthStr];
        } else {
          quittanceStatus[monthStr] = getQuittanceTypeForMonth(
            tenantId,
            monthStr,
          );
        }
      });
    });
  }

  const getMonthList = useCallback(() => {
    let monthsList = [];
    const startDate = moment().subtract(minMonthsFromNow - 1, "months");
    for (let i = 0; i < minMonthsFromNow; i++) {
      monthsList.push(startDate.format("MM/YYYY"));
      startDate.add(1, "month");
    }
    return monthsList;
  }, [minMonthsFromNow]);

  useEffect(() => {
    if (isDateFrom) {
      const months = getMonthList();
      setTenantQuittanceStatuses((prev) => {
        Object.keys(prev).forEach((tenantId) => {
          prev[tenantId] = Object.fromEntries(
            months.map((monthStr) => [
              monthStr,
              getQuittanceTypeForMonth(tenantId, monthStr),
            ]),
          );
        });
      });
    }
  }, [
    isDateFrom,
    getQuittanceTypeForMonth,
    minMonthsFromNow,
    setTenantQuittanceStatuses,
    getMonthList,
  ]);

  function selectTenantHandler(tenantId, months) {
    // On l'ajoute que s'il est pas déjà dans la liste
    if (!Object.keys(tenantQuittanceStatuses).includes(tenantId)) {
      if (isDateFrom) {
        months = getMonthList();
      }
      // initialize new quittance status for the added tenant
      const quittanceStatus = {};
      months.forEach((monthStr) => {
        quittanceStatus[monthStr] = getQuittanceTypeForMonth(
          tenantId,
          monthStr,
        );
      });

      setTenantQuittanceStatuses((prev) => {
        prev[tenantId] = quittanceStatus;
      });
    }
  }

  function deleteTenantHandler(id) {
    setTenantQuittanceStatuses((prev) => {
      delete prev[id];
    });
  }

  function changeQuittanceTypeHandler(tenantId, month, quittanceType) {
    setTenantQuittanceStatuses((prev) => {
      prev[tenantId][month] = quittanceType;
    });
  }

  async function previewQuittanceHandler(values, setSubmitting) {
    setSubmitting(true);
    try {
      const response = await axios.post(
        `tenants/quittance`,
        {
          quittance_statuses: tenantQuittanceStatuses,
          send_email: false,
        },
        {
          params: { preview: true },
        },
      );
      setListPreviewQuittances(response.data.previews);
      setHasExistingQuittances(response.data.has_existing_quittances);
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
    setSubmitting(false);
  }

  async function submitHandler(values) {
    try {
      const response = await axios.post(`tenants/quittance`, {
        quittance_statuses: tenantQuittanceStatuses,
        send_email: values.send_email,
      });
      setShowQuittances(true);
      setListQuittances(response.data);
      setListPreviewQuittances([]);
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  return (
    <Formik
      initialValues={{
        dates: [moment().format("MM/YYYY")],
        send_email: false,
        from: "",
      }}
      onSubmit={submitHandler}
    >
      {({ isSubmitting, values, setSubmitting, handleChange }) => {
        return (
          <ModalElement
            size={"medium"}
            onClose={() => {
              setShowQuittances(false);
              setListQuittances([]);
              setListPreviewQuittances([]);
              setTenantQuittanceStatuses({});
              setHasExistingQuittances(false);
              setError("");
            }}
          >
            <Form>
              <img
                src={`/photo_quittance.png`}
                alt="Quittance"
                className={"center marginB10"}
              />
              <h2 className={"cardTitle text-center"}>Générer une quittance</h2>
              <QuittanceSelectTenant
                tenantQuittanceStatuses={tenantQuittanceStatuses}
                onSelect={
                  !tenantId
                    ? (tenantId) => selectTenantHandler(tenantId, values.dates)
                    : undefined
                }
                onDelete={!tenantId ? deleteTenantHandler : undefined}
                onChangeQuittanceType={changeQuittanceTypeHandler}
                isLoading={isLoadingQuittances}
              />
              <Checkbox name={"send_email"} className={"marginT10"}>
                Envoyer ce document par email
              </Checkbox>
              {isDateFrom ? (
                <TextInput
                  type={"date"}
                  name={"from"}
                  label={"Date de départ"}
                  className={"marginT10"}
                  onChange={(e) => {
                    const today = moment();
                    const selectedMonth = moment(e.target.value).startOf(
                      "month",
                    );
                    if (selectedMonth.isAfter(today)) {
                      setMonthsFromNow(0);
                    } else {
                      setMonthsFromNow(
                        today.month() - selectedMonth.month() + 1 || 0,
                      );
                    }
                    handleChange(e);
                  }}
                />
              ) : (
                <>
                  <p className={"primaryText marginT10"}>
                    Année {moment().year()}
                  </p>
                  <div className={"flex wrap gap10 marginT10 marginB10"}>
                    {Array.from({ length: 4 }, (_, i) => (
                      <Checkbox
                        name="dates"
                        pillStyle
                        className="marginL5"
                        value={moment().subtract(i, "month").format("MM/YYYY")}
                        key={i}
                        onChange={(e) => {
                          toggleMonth(e.target.value);
                          handleChange(e);
                        }}
                      >
                        {moment().subtract(i, "month").format("MMMM")}
                      </Checkbox>
                    ))}
                    <Button
                      type={"button"}
                      buttonStyle={"secondary"}
                      className={"marginT20 marginB20 center"}
                      onClick={() => {
                        setIsDateFrom(true);
                        setMonthsFromNow(0);
                      }}
                    >
                      Générer toutes les quittances depuis une date donnée
                    </Button>
                  </div>
                </>
              )}
              <p className={"secondaryText marginB10"}>
                Attention, Qalimo ne re-créera pas les Quittances qui existent
                déjà. Si besoin de les re-générer, il faut les supprimer au
                préalable.
              </p>
              <p className={"secondaryText marginB10"}>
                Qalimo se base sur les appels de loyer pour générer les
                quittances. Si la date d'appel de loyer n'est pas encore passée
                et que vous forcez la génération de la quittance, celle-ci
                apparaîtra avec un montant à 0€
              </p>
              <div className={"flex-right flex wrap gap10"}>
                <Button
                  type={"button"}
                  disabled={
                    isSubmitting ||
                    tenantQuittanceStatuses.length === 0 ||
                    values.dates.length === 0
                  }
                  onClick={() => previewQuittanceHandler(values, setSubmitting)}
                >
                  Prévisualiser
                </Button>
                <FormikSubmitButton
                  disabled={
                    tenantQuittanceStatuses.length === 0 ||
                    values.dates.length === 0
                  }
                >
                  Générer
                </FormikSubmitButton>
              </div>
              {hasExistingQuittances && (
                <>
                  <p className="secondaryText marginT10">
                    Certaines quittances existaient déjà et ne seront pas
                    re-créées. Elles sont toujours disponibles dans l'onglet
                    Documents d'un locataire.
                  </p>
                  {values.send_email && (
                    <p className={"secondaryText italique marginB10"}>
                      Elles seront tout de même envoyées par mail au locataire.
                    </p>
                  )}
                </>
              )}
              <div
                dangerouslySetInnerHTML={{ __html: listPreviewQuittances }}
              />
              {error && (
                <Card
                  type={"error"}
                  className={"padding10 text-center marginT10"}
                >
                  {error}
                </Card>
              )}

              {showQuittances && (
                <Card type={"green"} className={"marginT10"}>
                  {values.send_email ? (
                    <TextOneOrMany
                      one={
                        "Le document suivant a été généré, et envoyé par mail au locataire"
                      }
                      many={
                        "Les documents suivants ont été générés, et envoyés par mail aux locataires."
                      }
                      none={"Aucun document n'a été généré"}
                      number={listQuittances.length}
                    />
                  ) : (
                    <TextOneOrMany
                      one={"Le document suivant a été généré."}
                      many={"Les documents suivants ont été générés."}
                      none={"Aucun document n'a été généré"}
                      number={listQuittances.length}
                    />
                  )}
                  <ListFiles files={listQuittances} />
                </Card>
              )}
            </Form>
          </ModalElement>
        );
      }}
    </Formik>
  );
}
