import StyledForm from "../../UI/StyledForm";
import { HousePrivatif, People } from "../../UI/Icons";
import TextInput from "../../UI/TextInput";
import Select from "../../UI/Select";
import useProperties from "../../../hooks/use-properties";

function TenantHeaderForm() {
  const { properties } = useProperties();

  return (
    <>
      <StyledForm
        label={"Nom du profil"}
        icon={<People stroke="#95A0B0" />}
        formElement={
          <TextInput
            type="text"
            name="profile_nom.nom_profile"
            placeholder="Nom du profil"
          />
        }
      />
      <StyledForm
        label={"Bien rattaché"}
        icon={<HousePrivatif />}
        formElement={
          <Select name="id_bien">
            <option value="">Selectionnez un bien</option>
            {properties.map((option) => (
              <option key={option.id} value={option.id}>
                {option.nom}
              </option>
            ))}
          </Select>
        }
      />
    </>
  );
}

export default TenantHeaderForm;
