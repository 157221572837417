import { useModal } from "../../hooks/use-modal";
import EdlItemForm from "./EdlItemForm";
import useEdlItems from "../../hooks/use-edl-items";
import { sanitizeValues } from "../../models/utils";
import React, { useState } from "react";
import EdlAddSharedItem from "./EdlAddSharedItem";
import styles from "./EdlShow.module.css";
import ButtonCard from "../UI/ButtonCard";
import IconAndText from "../UI/IconAndText";
import { Interrogation, Plus } from "../UI/Icons";
import Card from "../UI/Card";
import { handleAPIError } from "../../utils";
import DraggableItem from "../UI/DraggableItem";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import Tooltip from "../UI/Tooltip";
import DuplicateRooms from "./DuplicateRooms";
import ClesForm from "./Cles";
import useProperties from "../../hooks/use-properties";
import Button from "../UI/Button";

function EdlShow({ propertyId, isRealisation, property }) {
  const [AddPieceFormModal, closeAddPieceFormModal, openAddPieceFormModal] =
    useModal();
  const [DuplicateFormModal, closeDuplicateFormModal, openDuplicateFormModal] =
    useModal();
  const [
    AddSharedItemFormModal,
    closeAddSharedItemFormModal,
    openAddSharedItemFormModal,
  ] = useModal();
  const [AddCleModal, closeCleModal, openCleModal] = useModal();
  const {
    edlItems,
    edlItemsOrder,
    addEdlItem,
    updateEdlItem,
    updateEdlItemsOrder,
    deleteEdlItem,
    addSharedItem,
    generateEdlItems,
    duplicateEdlItem,
    importEdlItems,
  } = useEdlItems(propertyId);
  const { updateProperty } = useProperties();
  const [itemToEdit, setItemToEdit] = useState(null);
  const [error, setError] = useState("");
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor),
    useSensor(TouchSensor),
  );

  async function submitItemHandler(values, { resetForm }) {
    const sanitizedValues = { ...sanitizeValues(values), type: "edl" };
    delete sanitizedValues.template;

    try {
      if (itemToEdit) {
        await updateEdlItem(itemToEdit.id, sanitizedValues);
      } else {
        await addEdlItem(sanitizedValues);
      }
      resetForm();
      closeAddPieceFormModal();
      closeAddSharedItemFormModal();
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  async function addSharedItemHandler(values, { resetForm }) {
    const sanitizedValues = sanitizeValues(values);
    try {
      await addSharedItem(sanitizedValues);
      resetForm();
      closeAddSharedItemFormModal();
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteHandler(id) {
    try {
      await deleteEdlItem(id);
      closeAddPieceFormModal();
      closeAddSharedItemFormModal();
    } catch (error) {
      console.error(error);
    }
  }

  async function duplicateHandler(id) {
    try {
      await duplicateEdlItem(id);
      closeAddPieceFormModal();
    } catch (error) {
      console.error(error);
    }
  }

  async function importItemsHandler(itemIds) {
    await importEdlItems(itemIds);
    closeDuplicateFormModal();
  }

  async function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = edlItemsOrder.indexOf(active.id);
      const newIndex = edlItemsOrder.indexOf(over.id);
      try {
        await updateEdlItemsOrder(arrayMove(edlItemsOrder, oldIndex, newIndex));
      } catch (e) {
        console.error(e);
      }
    }
  }

  async function submitClesHandler(propertyId, keys) {
    await updateProperty(propertyId, keys);
    closeCleModal();
  }
  return (
    <>
      <AddPieceFormModal size={"ultra"}>
        <EdlItemForm
          itemToEdit={itemToEdit}
          onSubmit={submitItemHandler}
          onDelete={deleteHandler}
          onDuplicate={duplicateHandler}
          error={error}
        />
      </AddPieceFormModal>
      <ClesForm
        property={property}
        ModalElement={AddCleModal}
        onSubmit={(keys) => submitClesHandler(propertyId, keys)}
      />
      <DuplicateRooms
        propertyId={propertyId}
        onSubmit={importItemsHandler}
        ModalElement={DuplicateFormModal}
      />
      <AddSharedItemFormModal size={"medium"}>
        <EdlAddSharedItem
          onSubmit={addSharedItemHandler}
          currentItems={edlItems}
        />
      </AddSharedItemFormModal>
      <Card className={"flex space-between wrap gap10 marginB20 padding10"}>
        <ButtonCard
          type={"button"}
          onClick={() => {
            setItemToEdit(null);
            openAddPieceFormModal();
          }}
          className={"secondaryText padding10 flex-grow"}
          buttonStyle={"blue"}
        >
          <IconAndText
            text={"Ajouter une pièce manuellement"}
            icon={<Plus stroke="#707989" />}
          />
        </ButtonCard>
        <ButtonCard
          type={"button"}
          onClick={async () => {
            try {
              await generateEdlItems();
            } catch (e) {
              console.error(e);
            }
          }}
          className={"secondaryText padding10 flex-grow"}
          buttonStyle={"blue"}
        >
          <IconAndText
            text={"Importer les pièces depuis la description"}
            icon={<Plus stroke="#707989" />}
          />
        </ButtonCard>
        <ButtonCard
          type={"button"}
          className={"secondaryText padding10 flex-grow"}
          buttonStyle={"blue"}
          onClick={() => {
            setItemToEdit(null);
            openAddSharedItemFormModal();
          }}
        >
          <IconAndText
            text={"Ajouter une pièce partagée"}
            icon={<Plus stroke="#707989" />}
          />
        </ButtonCard>
        {!isRealisation && (
          <p className={"secondaryText marginB10 text-center w-100"}>
            Cette page permet de configurer vos pièces. Pour réaliser un état
            des lieux, allez sur votre locataire, et cliquez sur "Réaliser un
            état des lieux."
          </p>
        )}
      </Card>
      <div className={styles.grid}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={edlItemsOrder}>
            {edlItemsOrder?.map((edlId, index) => (
              <DraggableItem key={edlId} id={edlId}>
                <ButtonCard
                  key={edlId}
                  onClick={() => {
                    setItemToEdit(edlItems[index]);
                    openAddPieceFormModal();
                  }}
                  className={styles.relative}
                  padding={"xl"}
                >
                  {edlItems[index].is_shared && (
                    <div className={styles.sharedRoom}>Pièce partagée</div>
                  )}
                  <div>{edlItems[index].nom}</div>
                </ButtonCard>
              </DraggableItem>
            ))}
          </SortableContext>
        </DndContext>
        <ButtonCard onClick={openCleModal} padding={"xl"}>
          Clés
        </ButtonCard>
      </div>
      <div className={"secondaryText text-center marginT20"}>
        La liste est incomplète ?{" "}
        <Tooltip
          content={
            <>
              <p className={"primaryText marginB10"}>
                Des pièces n'apparaissent pas ?
              </p>
              <p className={"marginB10"}>
                Vous pouvez vous rendre dans la fiche du bien immobilier
                concerné, rubrique “Description du bien” complétez et validez
                les pièces du logement. Puis cliquez sur le bouton “Importer les
                pièces de la description”.
              </p>
              <p>
                Vous pouvez aussi créer la pièce manquante en cliquant sur
                “Ajouter une pièce manuellement”.
              </p>
            </>
          }
        >
          <Interrogation />
        </Tooltip>
        <Button
          onClick={openDuplicateFormModal}
          className={"marginL10"}
          padding={"sm"}
          buttonStyle={"link"}
        >
          Dupliquer une pièce d'un autre logement
        </Button>
      </div>
    </>
  );
}

export default EdlShow;
