import Card from "../UI/Card";
import useAccount from "../../hooks/use-account";
import Button from "../UI/Button";
import { useModal } from "../../hooks/use-modal";
import ChangePasswordForm from "./ChangePasswordForm";
import moment from "moment";
import {
  CalendarCheck,
  DrawSignature,
  Heart,
  Interrogation,
} from "../UI/Icons";
import Tooltip from "../UI/Tooltip";
import BuyCredits from "../Stripe/BuyCredits";
import useLoader from "../../hooks/use-loader";
import BuySubscriptionForm from "../Stripe/BuySubscriptionForm";
import CopyToClipboard from "../UI/CopyToClipboard";
import { classnames, TextOneOrMany, UNKNOWN_ERROR_MESSAGE } from "../../utils";
import SignaturePad from "../UI/SignaturePad";
import useLogo from "../../hooks/use-logo";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import Img from "../UI/Img";
import useSignature from "../../hooks/use-signature";
import styles from "./MonCompte.module.css";
import FileInput from "../UI/FileInput";
import { Form, Formik } from "formik";
import { DocType } from "../../utils/constants/documents";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { array, object } from "yup";
import SmtpForm from "./SmtpForm";
import { useState } from "react";
import { useStripeAbonnementPrice } from "../../hooks/use-stripe-prices";
import { TypeAbonnement } from "../../utils/constants/account";
import { getAbonnementDetails } from "../../utils/abonnement";
import Spinner from "../UI/Spinner";
import TextInputStandalone from "../UI/TextInputStandalone";
import CreditsCard from "./CreditsCard";
import ButtonCard from "../UI/ButtonCard";
import IconAndText from "../UI/IconAndText";
import useMediaQuery from "../../hooks/use-media-query";
import SignatureMailForm from "./SignatureMailForm";

export default function MonCompte() {
  const {
    account,
    filleuls,
    refresh: refreshAccount,
    changePassword,
    unsubscribe,
    updateCompte,
  } = useAccount();

  const nbBiens = account.nb_biens_abonnement;
  const nbBiensApresExpiration = account.nb_biens_abonnement_apres_expiration;
  const { price } = useStripeAbonnementPrice(
    account.stripe_price_abonnement,
    nbBiens,
  );
  const { price: priceApresExpiration } = useStripeAbonnementPrice(
    account.stripe_price_abonnement_apres_expiration,
    nbBiensApresExpiration,
  );

  const { logo, logoBlob, createLogo, deleteLogo } = useLogo(true);
  const { signature, signatureBlob, createSignature, deleteSignature } =
    useSignature(true);
  const [ChangePassword, closeChangePassword, openChangePassword] = useModal();
  const [SignatureModal, closeSignatureModal, openSignatureModal] = useModal();
  const [SignatureMailModal, closeSignatureMailModal, openSignatureMailModal] =
    useModal();
  const [SmtpModal, closeSmtpModal, openSmtpModal] = useModal();
  const [LogoModal, closeLogoModal, openLogoModal] = useModal();
  const [AccountBuyCredits, , openAccountBuyCredits] = useModal();
  const [SubscribeModal, closeSubscribeModal, openSubscribeModal] = useModal();
  const axios = useAuthenticatedAxios();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const [unsubscribing, unsubscribeLoader] = useLoader(async () => {
    try {
      await unsubscribe({ autorenew_abonnement: false });
    } catch (e) {
      console.error(e);
    }
  });
  const [mailResponse, setMailResponse] = useState(null);

  const referralLink = `${process.env.REACT_APP_FRONTEND_URL}/signup?referral=${account.id_parrainage}`;

  function dataURLToBlob(dataURL) {
    const bytes = Uint8Array.from(atob(dataURL.split(",")[1]), (c) =>
      c.charCodeAt(0),
    );
    return new Blob([bytes], { type: "image/png" });
  }

  async function saveSignatureHandler(sigCanvas) {
    const formData = new FormData();
    const blob = dataURLToBlob(sigCanvas.toDataURL("image/png"));
    formData.append("documents", blob, "signature.png");
    try {
      const res = await axios.post("/documents/upload", formData, {
        params: { doctype: DocType.SIGNATURE },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await createSignature({ file: res.data[0] });
      closeSignatureModal();
    } catch (error) {
      console.error(error);
    }
  }

  async function saveLogoHandler({ files }) {
    try {
      await createLogo({ file: files[0] });
      closeLogoModal();
    } catch (error) {
      console.error(error);
    }
  }

  async function saveSmtp(values) {
    await updateCompte(values);
    closeSmtpModal();
  }
  async function saveSignatureMailHandler(values) {
    await updateCompte(values);
    closeSignatureMailModal();
  }

  async function testMail() {
    let cardType = "error";
    let cardContent = UNKNOWN_ERROR_MESSAGE;
    try {
      const {
        data: { success, error },
      } = await axios.post("/account/test-smtp");
      cardType = success ? "green" : "error";
      cardContent = success
        ? "Tout semble OK, vous devriez recevoir un mail de test"
        : `Le serveur à retourné l'erreur suivante : ${error}`;
    } catch (error) {
      console.error(error);
    }
    setMailResponse(
      <Card type={cardType} className={"padding10"}>
        {cardContent}
      </Card>,
    );
  }

  const isAbonne =
    (account.expiration_abonnement === null ||
      moment(account.expiration_abonnement).isAfter(moment())) &&
    account.type_abonnement !== TypeAbonnement.GRATUIT;

  const { name, desc, recurrence, by, rateInt, rateDec } = getAbonnementDetails(
    price,
    isAbonne ? account.type_abonnement : TypeAbonnement.GRATUIT,
    nbBiens,
  );

  return (
    <>
      <SmtpModal>
        <SmtpForm onSubmit={saveSmtp} account={account} />
      </SmtpModal>
      <SignatureModal>
        <SignaturePad onSave={saveSignatureHandler} />
      </SignatureModal>
      <SignatureMailModal size={"medium"}>
        <SignatureMailForm
          onSave={saveSignatureMailHandler}
          account={account}
        />
      </SignatureMailModal>
      <LogoModal>
        <Formik
          initialValues={{ files: [] }}
          onSubmit={saveLogoHandler}
          validationSchema={object({
            files: array().of(object()).length(1, "Un logo est requis"),
          })}
        >
          <Form>
            <FileInput
              documentId={logo?.id}
              doctype={DocType.LOGO}
              label="Sélectionnez votre logo"
              format={"Format PNG, JPG, JPEG"}
              accept=".png,.jpg,.jpeg"
            />
            <FormikSubmitButton>Enregistrer</FormikSubmitButton>
          </Form>
        </Formik>
      </LogoModal>
      <ChangePassword>
        <ChangePasswordForm
          onSubmit={async (values) => {
            await changePassword(values);
            closeChangePassword();
          }}
        />
      </ChangePassword>
      <AccountBuyCredits size={"medium"}>
        <BuyCredits
          account={account}
          refreshAccount={refreshAccount}
          buyCredit
        />
      </AccountBuyCredits>
      <BuySubscriptionForm
        onSubscribed={closeSubscribeModal}
        Modal={SubscribeModal}
      />
      <div className={styles.wrapper}>
        <Card>
          <p className={"cardTitle marginB10"}>Informations personnelles</p>
          <div className={"flex gap10 align-items-center"}>
            {!isSmallScreen && (
              <img src={"/photo_mon_compte.jpg"} alt="mon-compte" />
            )}
            <div>
              <p className={"secondaryText"}>Adresse e-mail de connexion</p>
              <p className={"primaryText"}>{account.mail}</p>
              {account.telephone && (
                <>
                  <p className={"secondaryText marginT10"}>
                    Numéro de téléphone
                  </p>
                  <p className={"primaryText"}>{account.telephone}</p>
                </>
              )}
            </div>
          </div>
          <div className={"text-right marginT10"}>
            <Button buttonStyle={"secondary"} onClick={openChangePassword}>
              Modifier le mot de passe
            </Button>
          </div>
        </Card>
        <Card>
          <p className={"cardTitle"}>Votre abonnement</p>
          <div className={"marginT10 flex space-between align-items-end"}>
            <div>
              <p className={styles.subscriptionDesc}>
                Formule {name} <br />
                {desc}
              </p>
              <IconAndText
                text={
                  <>
                    <p>
                      {account.expiration_abonnement &&
                      account.autorenew_abonnement
                        ? "Prochaine échéance"
                        : "Date d'expiration"}{" "}
                      :
                      {isAbonne && account.expiration_abonnement
                        ? moment(account.expiration_abonnement).format(
                            "DD/MM/YYYY",
                          )
                        : "jamais"}
                    </p>
                    <p>
                      {account.type_abonnement === TypeAbonnement.TRIAL && (
                        <>
                          Puis :{" "}
                          <AbonnementType
                            type={account.type_abonnement_apres_expiration}
                            nbBiens={nbBiensApresExpiration}
                            stripePriceId={priceApresExpiration.id}
                          />
                        </>
                      )}
                    </p>
                  </>
                }
                icon={<CalendarCheck stroke={"#9D9D9D"} />}
                className={"marginT10"}
              />
            </div>

            <div>
              <p className={"primaryBlue text-center marginB5"}>
                Abonnement {recurrence}
              </p>
              <Card
                className={classnames("text-center", "bg-blue", styles.price)}
                padding={"md"}
              >
                {account.type_abonnement !== TypeAbonnement.GRATUIT &&
                account.type_abonnement !== TypeAbonnement.TRIAL ? (
                  <p className={styles.subscriptionPrice}>
                    <span className={styles.priceSymbol}>
                      € <span className={styles.priceInt}>{rateInt}</span>
                    </span>
                    <span className={styles.priceDec}>
                      {rateDec} TTC <br />
                      {by}
                    </span>
                  </p>
                ) : account.type_abonnement === TypeAbonnement.TRIAL ? (
                  "Essai gratuit"
                ) : (
                  "Gratuit"
                )}
              </Card>
            </div>
          </div>
          <div className={"marginT10 flex space-between marginT20 wrap gap10"}>
            <Button
              buttonStyle={"secondaryDelete"}
              disabled={!account.autorenew_abonnement}
              isLoading={unsubscribing}
              onClick={unsubscribeLoader}
            >
              Annuler l'abonnement
            </Button>
            <Button buttonStyle={"secondary"} onClick={openSubscribeModal}>
              {isAbonne ? "Changer d'abonnement" : "S'abonner"}
            </Button>
          </div>
        </Card>
        <Card>
          <p className={"cardTitle"}>Parrainages</p>
          <p className={"primaryBlue marginT20 marginB20"}>
            Parrainez vos proches. Un mois d’abonnement vous sera offert, ainsi
            qu’à votre filleul pour chaque parrainage !
            <span className={"marginL10"}>
              <Heart />
            </span>
          </p>
          <span className={"primaryText"}>Lien de parrainage </span>(
          {filleuls.nb_comptes}{" "}
          <TextOneOrMany
            one={"filleul"}
            many={"filleuls"}
            number={filleuls.nb_comptes}
          />
          )
          <div className="flex gap10 marginT10">
            <TextInputStandalone
              disabled
              value={referralLink}
              className={"flex-grow"}
            />
            <CopyToClipboard toCopy={referralLink} />{" "}
          </div>
          <p className={"primaryText marginT10"}>
            Nombre de mois gratuits cumulés : {filleuls.nb_comptes_abonnes}{" "}
            <Tooltip
              content={
                "Vous ne cumulez des mois gratuit que lorsque votre filleul souscrit à un abonnement."
              }
            >
              <Interrogation />
            </Tooltip>
          </p>
        </Card>
        <Card>
          <p className={"cardTitle marginB20"}>
            Vos crédits disponibles{" "}
            <Tooltip
              content={
                "Utilisés pour les signatures électroniques et lettres recommandées"
              }
            >
              <Interrogation />
            </Tooltip>
          </p>
          <CreditsCard
            credits={account.credits}
            className="marginAuto marginB20 padding20"
          />
          <div className={"text-center"}>
            <Button
              buttonStyle={"link"}
              className={"marginL10"}
              onClick={openAccountBuyCredits}
            >
              Acheter des crédits
            </Button>
          </div>
        </Card>
        <Card>
          <p className={"cardTitle"}>Signature des documents</p>
          <div className={"flex wrap gap10 marginT20"}>
            <ButtonCard onClick={openSignatureModal} className={"flex-grow"}>
              <IconAndText
                icon={<DrawSignature />}
                text={
                  <div className={"text-left"}>
                    <p className={"primaryText"}>Dessiner une signature</p>
                    <p className={"secondaryText"}>
                      Faire une signature numérique
                    </p>
                  </div>
                }
              />
            </ButtonCard>
            <Button
              onClick={() => deleteSignature(signature.id)}
              disabled={!signatureBlob}
              className={"flex-grow"}
              buttonStyle={"secondaryDelete"}
            >
              Supprimer la signature
            </Button>
          </div>
          {signatureBlob && (
            <Card padding={"none"} className={"marginT20"}>
              <Img
                blob={signatureBlob}
                alt="signature"
                className={styles.bgWhite}
              />
            </Card>
          )}
          {!signatureBlob && (
            <Card className={"marginT10 paddingL10 secondaryText text-center"}>
              Pas de signature enregistrée
            </Card>
          )}
        </Card>
        <Card>
          <p className={"cardTitle"}>
            Logo personnalisé sur les documents{" "}
            <Tooltip
              content={
                "Le logo enregistré ici apparaîtra sur tous les documents générés. Si aucun logo n'est renseigné, le logo Qalimo apparaîtra."
              }
            >
              <Interrogation />
            </Tooltip>
          </p>
          <div className={"flex wrap gap10 marginT20"}>
            <ButtonCard onClick={openLogoModal} className={"flex-grow"}>
              <IconAndText
                icon={<DrawSignature />}
                text={
                  <div className={"text-left"}>
                    <p className={"primaryText"}>Ajouter un logo</p>
                    <p className={"secondaryText"}>PNG, JPG, JPEG, PDF</p>
                  </div>
                }
              />
            </ButtonCard>
            <Button
              onClick={() => deleteLogo(logo.id)}
              disabled={!logoBlob}
              className={"flex-grow"}
              buttonStyle={"secondaryDelete"}
            >
              Supprimer le logo
            </Button>
          </div>
          <Card className={"marginT20 text-center"}>
            {logoBlob ? (
              <Img
                blob={logoBlob}
                alt="logo"
                style={{ "--bg-color": "white", display: "inline-block" }}
              />
            ) : (
              <img src={"/Qalimo-logo.png"} alt="logo" />
            )}
          </Card>
        </Card>
        <Card>
          <div className={"flex space-between gap10 wrap"}>
            <p className={"cardTitle"}>Serveur mail</p>
            <div className={"flex gap10 wrap"}>
              {account.smtp_serveur && (
                <Button buttonStyle={"secondary"} onClick={testMail}>
                  Tester la connection
                </Button>
              )}
              <Button buttonStyle={"secondary"} onClick={openSmtpModal}>
                Modifier
              </Button>
            </div>
          </div>
          <p className={"secondaryText marginT10 marginB20"}>
            Envoyez les mails à vos locataires depuis votre propre adresse de
            messagerie
          </p>

          <p>
            <span className={"primaryText"}>Serveur SMTP</span> :{" "}
            <span>{account.smtp_serveur ?? "Qalimo (default)"}</span>
          </p>
          <p className={"marginB20"}>
            <span className={"primaryText"}>Adresse mail</span> :{" "}
            <span>{account.smtp_mail ?? "no-reply@qalimo.fr"}</span>
          </p>
          {mailResponse}
        </Card>
        <Card>
          <div className={"flex space-between gap10 wrap"}>
            <p className={"cardTitle"}>Signature mail</p>
            <div className={"flex gap10 wrap"}>
              <Button
                buttonStyle={"secondary"}
                onClick={openSignatureMailModal}
              >
                Modifier
              </Button>
            </div>
          </div>
          <p className={"secondaryText marginT10 marginB20"}>
            Personnalisez la signature des mails envoyés.
          </p>
          <p className={"primaryText"}>Signature : </p>
          <pre className="pre-whitespace">{account.smtp_signature}</pre>
        </Card>
      </div>
    </>
  );
}

export function AbonnementType({ type, nbBiens, stripePriceId }) {
  const { price, isLoading } = useStripeAbonnementPrice(stripePriceId, nbBiens);
  if (isLoading) return <Spinner />;
  const { rate, name, desc } = getAbonnementDetails(price, type, nbBiens);
  if (!name) return "Aucun abonnement";
  return `${name} - ${desc} - ${rate}`;
}
