import Checkbox from "../UI/Checkbox";
import Radio from "../UI/Radio";
import RadioGroup from "../UI/RadioGroup";
import Select from "../UI/Select";
import TextInput from "../UI/TextInput";
import FormUpload from "../File/FormUpload";
import Tooltip from "../UI/Tooltip";
import { Interrogation } from "../UI/Icons";

function ProfileFormPerso({
  type,
  isBailleur,
  cautionType,
  isGarant,
  profile,
  withMandatoryFields,
  prefix = "",
  isMandataire,
}) {
  const formType = type === "2" ? "du représentant légal" : "";

  return (
    <>
      <h3>Informations personnelles</h3>
      <div>
        <RadioGroup className={"marginB10"}>
          <Radio name={`${prefix}type`} value="1" label="Personne physique" />
          <Radio name={`${prefix}type`} value="2" label="Personne morale" />
        </RadioGroup>

        <div className={"flex gap10"}>
          <TextInput
            type="text"
            name={`${prefix}prenom`}
            placeholder={"Prénom " + formType}
            label={"Prénom " + formType}
            mandatory
            className={"flex-grow"}
          />
          <TextInput
            type="text"
            name={`${prefix}nom`}
            placeholder={"Nom " + formType}
            label={"Nom " + formType}
            className={"flex-grow"}
            mandatory
          />
        </div>
        <TextInput
          type="email"
          name={`${prefix}mail`}
          placeholder="Adresse e-mail"
          label={
            <>
              Adresse e-mail{" "}
              {!withMandatoryFields && isBailleur && (
                <Tooltip
                  content={
                    "Si votre locataire n'a pas d'adresse e-mail, indiquez la votre."
                  }
                >
                  <Interrogation />
                </Tooltip>
              )}
            </>
          }
          mandatory
        />
        <TextInput
          type="text"
          name={`${prefix}tel`}
          placeholder="Téléphone"
          label="Téléphone"
          mandatory={!!withMandatoryFields}
        />
        {type === "1" && (
          <div>
            <TextInput
              type="date"
              name={`${prefix}birthday`}
              placeholder="Date de naissance"
              label="Date de naissance"
              mandatory={!!withMandatoryFields}
            />
            <TextInput
              type="text"
              name={`${prefix}lieu_naissance`}
              placeholder="Lieu de naissance"
              label="Lieu de naissance"
              mandatory={!!withMandatoryFields}
            />
            <TextInput
              type="text"
              name={`${prefix}nationalite`}
              placeholder="Nationalité"
              label="Nationalité"
              mandatory={!!withMandatoryFields}
            />
          </div>
        )}
        {type === "2" && (
          <div>
            <Select label="Type de société" name={`${prefix}socType`} mandatory>
              <option value="">Selectionnez un type</option>
              <option value="SAS">SAS</option>
              <option value="SCI">SCI</option>
              <option value="SARL">SARL</option>
              <option value="SARL">SARL de famille</option>
              <option value="autre">Autre</option>
            </Select>
            {!isMandataire && (
              <Checkbox name="sci_famille">SCI Familiale</Checkbox>
            )}
            <br />
            <TextInput
              type="text"
              name={`${prefix}nom_societe`}
              placeholder="Dénomination sociale"
              label="Dénomination sociale"
              mandatory
            />
            <TextInput
              type="text"
              name={`${prefix}siret`}
              placeholder="Numéro RCS/SIRET"
              label="Numéro RCS/SIRET"
              mandatory
            />
          </div>
        )}
        {!isBailleur && !isGarant && !profile?.garantor_of && !isMandataire && (
          <>
            <Select name={`${prefix}caution_type`} label={"Type de garantie"}>
              <option value={""}>Choisissez un type de garantie</option>
              <option value={"Physique"}>Garant physique</option>
              <option value={"Visale"}>Visale</option>
              <option value={"Autre"}>Autre</option>
            </Select>
            {cautionType === "Physique" && (
              <p className={"secondaryText marginB10"}>
                Vous pourrez ajouter les informations concernant votre garant
                après avoir complété votre profil
              </p>
            )}
            {cautionType === "Autre" && (
              <TextInput
                type={"text"}
                name={`${prefix}contract_number`}
                label={"Numéro de contrat (si nécessaire)"}
              />
            )}
            {cautionType === "Visale" && (
              <>
                <TextInput
                  type={"text"}
                  name={`${prefix}contract_number`}
                  label={"Numéro de visa VISALE"}
                />
                <TextInput
                  type={"number"}
                  step={"0.01"}
                  name={`${prefix}loyer_max_visale`}
                  label={"Montant maximum garanti"}
                />
                <TextInput
                  type={"date"}
                  name={`${prefix}validite_visale`}
                  label={"Date de validité du visa"}
                />
              </>
            )}
          </>
        )}
        {!isBailleur && !isMandataire && (
          <>
            Justificatif d'identité
            <FormUpload tag="identite" />
          </>
        )}
      </div>
    </>
  );
}

export default ProfileFormPerso;
