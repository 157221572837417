import moment from "moment/moment";
import { BalanceTypeCategory } from "./constants/balances";

export const UNKNOWN_ERROR_MESSAGE =
  "Erreur inattendue. L'équipe Qalimo a été informée de ce problème. Si c'est la 1ère fois que vous le rencontrez, rafraichissez la page et réessayez.";
export const VALIDATION_ERROR_MESSAGE =
  "Les données saisies sembles incorrectes, veuillez vérifier votre saisie. Le serveur a renvoyé l'erreur suivante : ";

export function handleAPIError(error) {
  if (error.response) {
    const errorStr = JSON.stringify(error.response.data, null, 1);
    console.error(`Status ${error.response.status} : ${errorStr}`);
    if (error.response.status === 400) {
      return (
        <>
          {VALIDATION_ERROR_MESSAGE}
          <pre className={"text-left"}>{errorStr}</pre>
        </>
      );
    } else if (error.response.status === 420) {
      return <>Paiement refusé</>;
    }
    return UNKNOWN_ERROR_MESSAGE;
  } else {
    console.error(error);
    return UNKNOWN_ERROR_MESSAGE;
  }
}

export function findAndUpdate(list, matcher, updatedItem) {
  const index = list.findIndex(matcher);
  if (index === -1) {
    throw new Error("Item non trouvé");
  }
  const newList = [...list];
  newList[index] = updatedItem;
  return newList;
}

export function classnames(...classes) {
  return classes
    .filter((clazz) => typeof clazz === "string" && clazz !== "")
    .join(" ");
}

export function TextOneOrMany({ one, many, none, number }) {
  if (!number) return none ?? one;
  else if (number === 1) return one;
  else return many;
}

export function getNewLoyerFromIndice(montant, oldIndice, newIndice) {
  const newMontant = (montant * newIndice) / oldIndice;
  return +newMontant.toFixed(2);
}

export function getLastAnniversaryYear(date) {
  const today = moment();
  if (moment(date).year(today.year()) > today) {
    // Si la date d'anniversaire donnée, avec l'année d'aujd est après aujd,
    // le dernier anniversaire était l'année d'avant
    return today.year() - 1;
  } else {
    // Sinon l'anniversaire était cette année
    return today.year();
  }
}

export function getNextAnniversaryDate(date) {
  return moment(date).year(getLastAnniversaryYear(date) + 1);
}

export function flattenKeys(input, prefix = "", isArray = false) {
  if (typeof input !== "object") {
    return [prefix];
  }

  return Object.keys(input)
    .map((k) => {
      let suffix = `.${k}`;
      if (!prefix) suffix = `${k}`;
      else if (isArray) suffix = `[${k}]`;

      return flattenKeys(
        input[k],
        `${prefix}${suffix}`,
        Array.isArray(input[k]),
      );
    })
    .flat();
}

export function isImage(file) {
  return file.mimetype?.startsWith("image");
}

export function isPdf(file) {
  return file.mimetype === "application/pdf";
}

export function showFormikFieldsInError(errors, setFieldTouched) {
  for (const key of flattenKeys(errors)) {
    setFieldTouched(key, true, false);
  }
}

export function setBalanceAmountSignFromType(balance, balanceTypesByCategory) {
  if (
    balanceTypesByCategory[BalanceTypeCategory.LOCATAIRE_APPEL]
      .map((balanceType) => balanceType.static_id)
      .includes(balance.type)
  ) {
    balance.montant = -Math.abs(balance.montant);
  }
}
