import Select from "../../UI/Select";
import TextInput from "../../UI/TextInput";
import Radio from "../../UI/Radio";
import RadioGroup from "../../UI/RadioGroup";
import ZoneTendue from "./ZoneTendue";
import useLeaseTemplates from "../../../hooks/use-lease-templates";
import Tooltip from "../../UI/Tooltip";
import { Link } from "react-router-dom";
import { Interrogation } from "../../UI/Icons";
import Checkbox from "../../UI/Checkbox";
import ExpandableCheckboxList from "../../UI/ExpandableCheckboxList";
import Card from "../../UI/Card";
import React, { Fragment, useEffect } from "react";
import { DETAIL_CHARGES } from "../../../models/property";

const BAIL_COMMERCIAUX = [
  "commercial",
  "commercial_derogatoire",
  "professionnel",
];

const BAIL_GARAGE = ["garage", "stockage"];

function Finance({ values, setFieldValue, edit }) {
  const { leaseTemplates } = useLeaseTemplates();

  const leaseTemplate = leaseTemplates.find(
    (lease) => parseInt(values.id_lease_template) === lease.id,
  );
  let filteredLeaseTemplates;
  if (values.type === "commercial") {
    filteredLeaseTemplates = leaseTemplates.filter((template) =>
      BAIL_COMMERCIAUX.includes(template.type),
    );
  } else if (values.type === "garage") {
    filteredLeaseTemplates = leaseTemplates.filter((template) =>
      BAIL_GARAGE.includes(template.type),
    );
  } else {
    if (values.is_coloc) {
      filteredLeaseTemplates = leaseTemplates.filter(
        (template) => template.colocation,
      );
    } else {
      filteredLeaseTemplates = leaseTemplates.filter(
        (template) =>
          !BAIL_GARAGE.concat(BAIL_COMMERCIAUX).includes(template.type) &&
          !template.colocation,
      );
    }
  }
  const detailChargesCheckboxItems = [
    ...DETAIL_CHARGES,
    ...values.detail_charges.filter((i) => !DETAIL_CHARGES.includes(i)),
  ];

  function addItem(fieldName, item) {
    if (values[fieldName] === undefined || values[fieldName] === null) {
      setFieldValue(fieldName, [item]);
    } else if (!values[fieldName].includes(item)) {
      setFieldValue(fieldName, [...values[fieldName], item]);
    }
  }
  const lease = leaseTemplates.find(
    (template) => template.id === parseInt(values.id_lease_template),
  );

  const typeChargeProvisionForced =
    lease?.type !== "meuble" && lease?.type !== "etudiant";

  useEffect(() => {
    if (typeChargeProvisionForced) {
      setFieldValue("type_charge", "provision");
    }
  }, [leaseTemplates, setFieldValue, lease, typeChargeProvisionForced]);

  return (
    <>
      <Select name="id_lease_template" label="Type de bail" mandatory>
        <option value="">Sélectionner un type de bail</option>
        {filteredLeaseTemplates?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.nom}
          </option>
        ))}
      </Select>
      {lease?.colocation && !lease?.collectif && !edit && (
        <>
          <Card padding={"sm"} type={"warning"} className={"marginB10"}>
            Dans le cadre d'une colocation avec baux individuels, chaque chambre
            comptera comme un bien à part entière. Nous allons configurer toutes
            les chambres d'un coup, entrez le nombre de chambre que vous
            souhaitez créer dans le champs ci-dessous. Vous pourrez mettre un
            loyer et un nom différent pour chaque chambre après les avoir créés.
          </Card>
          <TextInput
            name={"nb_rooms"}
            type={"number"}
            label={"Nombre de chambre à créer"}
          />
        </>
      )}
      <div className={"gridColWrap240"}>
        <TextInput
          type="number"
          name="loyer_hc"
          placeholder="380,10"
          step={"0.01"}
          unit={"€"}
          label={`Loyer hors charge ${
            values.zone_tendue === "true" ? " (hors complément de loyer)" : ""
          }`}
          mandatory
        />
        <TextInput
          type="number"
          name="charges"
          placeholder="50"
          step={"0.01"}
          unit={"€"}
          label="Charges"
          mandatory
        />
      </div>

      <Checkbox name={"tva"} className={"marginB10"}>
        Le loyer est soumis à la TVA
      </Checkbox>
      {values.type === "commercial" && (
        <>
          Détail des charges facturées :
          <ExpandableCheckboxList
            name="detail_charges"
            items={detailChargesCheckboxItems}
            addItem={addItem}
            classNameG={"marginT10 marginB20"}
          />
        </>
      )}

      {values.type !== "commercial" && (
        <>
          {!typeChargeProvisionForced && (
            <RadioGroup>
              <Radio
                name="type_charge"
                value="provision"
                label="Provision sur charge"
              />
              <Radio
                name="type_charge"
                value="forfait"
                label="Forfait de charge"
              />
            </RadioGroup>
          )}

          {values.type === "habitation" && (
            <Select name="avantage_fiscal" label="Loi de défiscalisation">
              <option key="aucun" value="aucun">
                Aucun
              </option>
              <option key="pinel" value="pinel">
                Pinel
              </option>
              <option key="denormandie" value="Denormandie">
                Denormandie
              </option>
              <option key="derobien" value="de Robien">
                De Robien
              </option>
            </Select>
          )}
        </>
      )}

      <div className={"gridColWrap240"}>
        <TextInput
          type="number"
          name="deposit"
          placeholder="350"
          step={"0.01"}
          unit={"€"}
          label="Montant du dépôt de garantie"
          mandatory
        />

        <TextInput
          type="number"
          name="day_payment"
          placeholder="05"
          unit={"du mois"}
          mandatory
          label="Jour de paiement du loyer"
        />
      </div>
      {leaseTemplate?.type === "mobilite" && (
        <Card type={"error"} className={"padding5 marginB10"}>
          Attention, il n'est pas autorisé de prendre un dépôt de garantie dans
          le cadre d'un bail mobilité. Cette valeur ne sera donc pas prise en
          compte lors de l'édition du bail.
        </Card>
      )}

      {values.type === "habitation" && (
        <div className={"marginB10"}>
          <div className={"marginB10"}>
            Votre logement est il situé en zone tendue ?{" "}
            <Tooltip
              content={
                <p>
                  Rendez-vous sur le{" "}
                  <Link
                    to={
                      "https://www.service-public.fr/simulateur/calcul/zones-tendues"
                    }
                    target={"_blank"}
                  >
                    simulateur du gouvernement
                  </Link>{" "}
                  pour savoir si votre bien est situé en zone tendue
                </p>
              }
            >
              <Interrogation />
            </Tooltip>
          </div>
          <div className={"gridColWrap240"}>
            <Radio
              name="zone_tendue"
              value="true"
              label="Zone tendue"
              dependon={"id_parent"}
            />
            <Radio
              name="zone_tendue"
              value="false"
              label="Zone non tendue"
              dependon={"id_parent"}
            />
          </div>
        </div>
      )}
      {values.type === "commercial" && (
        <>
          <p>Indice d'indexation du loyer : </p>
          <div className={"gridColWrap120 marginT5 marginB10"}>
            <Radio
              name="type_indice"
              value="ILC"
              label="ILC"
              dependon={"id_parent"}
            />
            <Radio
              name="type_indice"
              value="ILAT"
              label="ILAT"
              dependon={"id_parent"}
            />
            <Radio
              name="type_indice"
              value="ICC"
              label="ICC"
              dependon={"id_parent"}
            />
            <Radio
              name="type_indice"
              value="Aucun"
              label="Aucun"
              dependon={"id_parent"}
            />
          </div>
        </>
      )}

      {values.zone_tendue === "true" && (
        <ZoneTendue loyerReference={values.loyer_reference === "true"} />
      )}
    </>
  );
}

export default Finance;
