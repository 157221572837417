import { currencyFormatter } from "../api/Functions";
import { TypeAbonnement } from "./constants/account";

export function monthsToRecurrence(months) {
  switch (months) {
    case 1:
      return { by: "par mois", recurrence: "mensuel" };
    case 12:
      return { by: "par an", recurrence: "annuel" };
    default:
      return "";
  }
}

export function getAbonnementDetails(price, typeAbonnement, nbBiens) {
  let rate = "";
  let rateInt = "";
  let rateDec = "";
  let name = "";
  let desc = "";
  let { by, recurrence } = monthsToRecurrence(price?.months);
  if (typeAbonnement === TypeAbonnement.GRATUIT) {
    return {
      name: "Gratteur",
      desc: "1 bien",
    };
  } else if (typeAbonnement === TypeAbonnement.TRIAL) {
    return {
      name: "Essai gratuit",
      desc: "Biens illimités",
    };
  }
  if (!price) return {};

  const rawRate = price.unit_amount / 100;
  const unitAmountStr = price.unit_amount.toString();
  rateInt = unitAmountStr.slice(0, unitAmountStr.length - 2);
  rateDec = unitAmountStr.slice(unitAmountStr.length - 2);
  rate = `${currencyFormatter(rawRate)} ${by}`;

  switch (typeAbonnement) {
    case TypeAbonnement.EARLYBIRD:
      name = "Early Bird";
      desc = "Biens illimités";
      break;
    case TypeAbonnement.REGULAR:
      if (nbBiens >= 7) {
        name = "Rentier";
        desc = "Biens illimités";
      } else {
        name = "Investisseur";
        desc = `${nbBiens} biens`;
      }
      break;
    case TypeAbonnement.GRATUIT:
      name = "Gratteur";
      desc = "1 bien";
      break;
    case TypeAbonnement.TRIAL:
      name = "Essai gratuit";
      desc = "Biens illimités";
      break;
    default:
      return {};
  }
  return { name, desc, rate, recurrence, by, rateInt, rateDec };
}
